import React from 'react';
import './styles/NavigationButtons.scss';

const NavigationButtons = ({ onEventChange, showLeft, showRight }) => {
  const leftVisibilityClass = showLeft ? '' : 'hide';
  const rightVisibilityClass = showRight ? '' : 'hide';

  const handleRight = () => {
    onEventChange('right');
  };

  const handleLeft = () => {
    onEventChange('left');
  };

  return (
    <div className='nav-button-container'>
      <div
        className={`nav-button button-left ${leftVisibilityClass}`}
        onClick={handleLeft}
      >
        <i className='fas fa-chevron-left' />
      </div>
      <div
        className={`nav-button button-right ${rightVisibilityClass}`}
        onClick={handleRight}
      >
        <i className='fas fa-chevron-right' />
      </div>
    </div>
  );
};

export default NavigationButtons;
