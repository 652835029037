import React, { useEffect, useContext } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom';

import PieceDisplay from './PieceDisplay.js';

import PortfolioContext from './../../context/PortfolioContext';
import { isMobile } from '../../misc/DeviceCheck';
import './styles/PieceDisplay.scss';

const PieceDisplayPage = props => {
  const { isPortfolioLoading } = useContext(PortfolioContext);
  const params = useParams();
  const pieceCategory = params.category;
  const pieceName = params.name;
  const url = usePieceURL(pieceCategory, pieceName);
  const modalClass = props.isModal ? 'modal' : '';

  useEffect(() => {
    scrollToTop();
  }, []);

  if (!url && !isPortfolioLoading) <Navigate to='/' />;

  return (
    <div className={`piece-master-view  ${modalClass}`}>
      <div className='modal-background' />
      <Link className='piece-container' to='/'>
        {isPortfolioLoading ? <div /> : <PieceDisplay url={url} />}
      </Link>
    </div>
  );
};

const usePieceURL = (category, name) => {
  const { portfolio } = useContext(PortfolioContext);

  const categoryMatch = portfolio.find(_category => {
    const categoryTitle = _category.title;
    const sanitizedTitle = decodeURIComponent(categoryTitle);

    return sanitizedTitle === category;
  });

  if (!categoryMatch) return null;

  const restoredName = decodeURIComponent(name);
  const pieceMatch = categoryMatch.rows.find(row =>
    row.names.includes(restoredName)
  );

  if (!pieceMatch) return null;

  const imageIndex = pieceMatch.names.indexOf(restoredName);

  return pieceMatch.images[imageIndex];
};

const scrollToTop = () => {
  if (isMobile()) {
    window.scrollTo(0, 0);
  }
};

export default PieceDisplayPage;
