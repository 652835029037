import React from 'react';
import { NavLink } from 'react-router-dom';

import ShopLinks from './ShopLinks/ShopLinks';

const NavigationMenu = ({ onClick }) => {
  return (
    <nav onClick={onClick}>
      <NavLink className='sidebar-link' exact to='/'>
        Portfolio
      </NavLink>
      <NavLink className='sidebar-link' to='/about'>
        About
      </NavLink>
      <ShopLinks />
    </nav>
  );
};

export default NavigationMenu;
