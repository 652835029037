import React from 'react';

const NoMatch = () => (
    <div className='no-match'>
        <h1>404</h1>
        <p>Not a valid URL</p>
    </div>
);

export default NoMatch;
