import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImageLoader from '../ImageLoader/ImageLoader';

const PortfolioPiece = ({ name, category, src, isWide }) => {
  const sanitizedCategory = encodeURIComponent(category);
  const sanitizedName = encodeURIComponent(name);
  const link = `/${sanitizedCategory}/${sanitizedName}`;
  const className = isWide ? 'span-two-columns' : '';

  return (
    <div className={`piece-wrapper ${className}`}>
      <Link
        className='portfolio-piece'
        to={{
          pathname: encodeURI(link),
          state: { modal: true },
        }}
      >
        <ImageLoader src={src} alt={name} />
      </Link>
      <div className='piece-title'>{name}</div>
    </div>
  );
};

PortfolioPiece.propTypes = {
  src: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default PortfolioPiece;
