import React, { useRef, useState } from 'react';
import NavigationButtons from './NavigationButtons';

import { v4 as uuidv4 } from 'uuid';
import { isMobile } from '../../../misc/DeviceCheck';

import './styles/CategoryTabs.scss';
import { Link } from 'react-router-dom';

const CategoryTabs = ({ names, activeTab, onChange }) => {
  const containerRef = useRef();
  const tabsRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [tabsWidth, setTabsWidth] = useState(0);

  const updatePosition = direction => {
    let newPosition = 0;
    const scrollMovement = containerWidth / 2;

    if (direction === 'right') {
      newPosition = scrollPosition + scrollMovement;
    } else if (direction === 'left') {
      newPosition = scrollPosition - scrollMovement;
    }

    if (newPosition > tabsWidth - newPosition) {
      newPosition = tabsWidth - containerWidth;
    } else if (newPosition < 0) {
      newPosition = 0;
    }

    containerRef.current.scrollTo(newPosition, 0);
    setScrollPosition(newPosition);
  };

  const currentTabs = tabsRef.current;
  if (currentTabs && currentTabs.offsetWidth !== tabsWidth) {
    setTabsWidth(currentTabs.offsetWidth);
  }

  const currentContainer = containerRef.current;
  if (currentContainer && currentContainer.offsetWidth !== containerWidth) {
    setContainerWidth(currentContainer.offsetWidth);
  }

  let showLeft, showRight;

  if (isMobile()) {
    showLeft = showRight = false;
  } else {
    showLeft = scrollPosition > 0;
    showRight =
      scrollPosition !== tabsWidth - containerWidth &&
      tabsWidth > containerWidth;
  }

  return (
    <div className='portfolio-tab-region'>
      <NavigationButtons
        onEventChange={updatePosition}
        showLeft={showLeft}
        showRight={showRight}
      />
      <div
        className='portfolio-tab-container disable-scrollbars'
        ref={containerRef}
      >
        <div id='portfolio-tabs' ref={tabsRef}>
          {names.map((name, index) => {
            const key = uuidv4();
            const isActive = index === activeTab;
            const classes = 'tab ' + (isActive ? 'active' : '');
            let url = `/${name}/`;

            if (index === 0) url = '/';

            return (
              <Link key={key} to={url}>
                <div className={classes} onClick={() => onChange(index)}>
                  {name}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CategoryTabs.defaultProps = {
  names: [],
};

export default CategoryTabs;
