import React from 'react';
import { PrismicProvider } from '@prismicio/react';
import { RouterProvider } from 'react-router';
import { client } from './prismic';
import ProfileContextWrap from './Components/PortfolioContextWrap';
import { router } from './Routes';

import './App.scss';

const App = () => {
  return (
    <PrismicProvider client={client}>
      <ProfileContextWrap>
        <RouterProvider router={router} />
      </ProfileContextWrap>
    </PrismicProvider>
  );
};

export default App;
