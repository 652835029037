import React from 'react';

const Attribution = () => {
  return (
    <div className='attributes'>
      Attribution:
      <p>
        Twitter, Instagram icons made by Freepik from&nbsp;
        <a
          href='https://www.flaticon.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          flaticon.com
        </a>
      </p>
      <p>
        Tumblr icon made by Roundicons from&nbsp;
        <a
          href='https://www.flaticon.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          flaticon.com
        </a>
      </p>
      <p>
        Email icon made by wishforge.games from&nbsp;
        <a href='freeicons.io' target='_blank' rel='noopener noreferrer'>
          freeicons.io
        </a>
      </p>
    </div>
  );
};

export default Attribution;
