import { createBrowserRouter } from 'react-router-dom';
import PortfolioDisplay from './Components/Portfolio/PortfolioDisplay';
import PieceDisplayPage from './Components/PieceDisplayPage/PieceDisplayPage';
import Layout from './Components/Layout';
import About from './Components/About/About';
import NoMatch from './Components/NoMatch';

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <PortfolioDisplay />,
      },
      {
        path: '/:category',
        element: <PortfolioDisplay />,
        children: [
          {
            path: '/:category/:name',
            element: <PieceDisplayPage isModal />,
          },
        ],
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/404',
        element: <NoMatch />,
      },
    ],
  },
]);
