import React, { useState, useEffect } from 'react';
import ProfileContext from '../context/PortfolioContext';

import { useAllPrismicDocumentsByType } from '@prismicio/react';

const ProfileContextWrap = ({ children }) => {
  const [portfolio, setPortfolio] = useState([]);
  const [isPortfolioLoading, setPortfolioLoading] = useState(true);

  const portfolioRes = useAllPrismicDocumentsByType('portfolio_page', {
    orderings: {
      field: 'my.portfolio_page.order',
      order: 'asc',
    },
  });

  useEffect(() => {
    const portfolioIsUnset = portfolio.length === 0;
    const portfolioResIsLoaded =
      portfolioRes && portfolioRes[1].state === 'loaded';

    if (portfolioIsUnset && portfolioResIsLoaded) {
      const filtered = filterResponse(portfolioRes[0]);
      setPortfolio(filtered);
      setPortfolioLoading(false);
    }
  }, [portfolioRes, portfolio.length]);

  return (
    <ProfileContext.Provider value={{ portfolio, isPortfolioLoading }}>
      {children}
    </ProfileContext.Provider>
  );
};

class PortfolioData {
  constructor(title, rows) {
    this.title = title;
    this.rows = rows;
  }
}

class Row {
  constructor(images, names) {
    this.images = images;
    this.names = names;
  }
}

const filterResponse = response => {
  const filtered = [];

  for (const element of response) {
    const { data } = element;
    const title = data.title[0].text;
    const rows = [];

    for (const row of data.portfolio_row) {
      const images = [];
      const names = [];

      if (row.image.url) images.push(row.image.url);
      if (row.name1[0]) names.push(row.name1[0].text);

      if (row.image2.url) images.push(row.image2.url);
      if (row.name2[0]) names.push(row.name2[0].text);

      if (row.image3.url) images.push(row.image3.url);
      if (row.name3[0]) names.push(row.name3[0].text);

      rows.push(new Row(images, names));
    }

    filtered.push(new PortfolioData(title, rows));
  }

  return filtered;
};

export default ProfileContextWrap;
