import React, { useState, useEffect } from 'react';

import Attribution from './Attribution';
import CallToAction from './CallToAction';

import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { PrismicRichText } from '@prismicio/react';

import './styles/About.scss';
import './styles/About-mobile.scss';

const About = () => {
  const [about, setAbout] = useState(new AboutData());
  const [aboutRes] = useAllPrismicDocumentsByType('about_me');

  useEffect(() => {
    const aboutIsUnset = about.profilePicture === '';

    if (aboutIsUnset && aboutRes) {
      const filtered = new AboutData();
      const { data } = aboutRes[0];

      filtered.profilePicture = data.profile_picture;
      filtered.aboutText = data.about_text;

      filtered.bottomImage = data.bottom_image;
      filtered.callToActionText = data.call_to_action_text;

      setAbout(filtered);
    }
  }, [aboutRes, about.profilePicture]);

  return (
    <div className='about-me-wrapper'>
      <div id='about-me'>
        <div className='profile-picture-container'>
          <img src={about.profilePicture.url} alt={about.profilePicture.alt} />
        </div>
        <h1>ABOUT ME</h1>
        <PrismicRichText field={about.aboutText} />
      </div>
      <CallToAction banner={about.bottomImage.url}>
        <PrismicRichText field={about.callToActionText} />
      </CallToAction>
      <Attribution />
    </div>
  );
};

class AboutData {
  aboutText = [];
  profilePicture = '';
  process1Text = [];
  process1Title = [];
  process1Image = '';
  process2Title = [];
  process2Text = [];
  process2Image = '';
  process3Title = [];
  process3Text = [];
  process3Image = '';
  bottomImage = '';
}

export default About;
