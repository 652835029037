import React, { useState, useContext, useEffect } from 'react';

import PortfolioPiece from './PortfolioPiece';
import PortfolioContext from './../../context/PortfolioContext';
import CategoryTabs from './CategoryTabs/CategoryTabs';

import { Outlet, useParams } from 'react-router';

import './styles/Portfolio.scss';

const PortfolioDisplay = () => {
  const { category } = useParams();
  const { portfolio } = useContext(PortfolioContext);
  const [activeTab, setActiveTab] = useState(0);
  const currentCategory = portfolio.length > 0 ? portfolio[activeTab].rows : [];

  useEffect(() => {
    if (category) {
      const matchIndex = portfolio.findIndex(x => x.title === category);
      const newIndex = matchIndex > 0 ? matchIndex : 0;

      setActiveTab(newIndex);
    }
  }, [portfolio, category]);

  const categories = portfolio.map(group => group.title);
  const changeActiveTab = index => setActiveTab(index);

  return (
    <>
      <div id='portfolio-display'>
        <CategoryTabs
          names={categories}
          activeTab={activeTab}
          onChange={changeActiveTab}
        />
        <div className='portfolio-pieces'>
          {currentCategory.map(row => {
            const { images, names } = row;
            const html = [];
            const currentCategoryName = portfolio[activeTab].title;

            for (const item in images) {
              let name = names[item];
              const src = images[item];

              const piece = (
                <PortfolioPiece
                  key={name}
                  src={src}
                  name={name}
                  category={currentCategoryName}
                  isWide={images.length === 1}
                />
              );

              html.push(piece);
            }

            return html;
          })}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default PortfolioDisplay;
