import React from 'react';

import './styles/Footer.scss';

const Footer = () => (
  <footer>
    <p>&copy; nastia zuchko</p>
  </footer>
);

export default Footer;
