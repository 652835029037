import React from 'react';
import SiteHead from './SiteHead/SiteHead';

import { Outlet } from 'react-router';

const Layout = () => {
  return (
    <>
      <SiteHead />
      <Outlet />
    </>
  );
};

export default Layout;
