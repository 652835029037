import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useSinglePrismicDocument } from '@prismicio/react';

const ShopLinks = () => {
  const [shopLinks, setShopLinks] = useState([]);
  const shopLinksRes = useSinglePrismicDocument('shop_links');

  useEffect(() => {
    const shopLinksSet = shopLinks.length > 0;
    const shopLinksLoaded = shopLinksRes && shopLinksRes[1].state === 'loaded';

    if (!shopLinksSet && shopLinksLoaded) {
      setShopLinks(formatResponse(shopLinksRes[0]));
    }
  }, [shopLinks, shopLinksRes]);

  return (
    <div className='shop-links'>
      {shopLinks.map(link => {
        const { name, url } = link;
        const key = uuid();
        return (
          <a
            className='sidebar-link'
            href={url}
            target='_blank'
            rel='noopener noreferrer'
            key={key}
          >
            {name}
          </a>
        );
      })}
    </div>
  );
};

const formatResponse = response => {
  const { data } = response;
  const { links } = data;

  const formattedLinks = links.map(link => {
    const { name, url } = link;

    return {
      name: name[0].text,
      url: url.url,
    };
  });

  return formattedLinks;
};

export default ShopLinks;
